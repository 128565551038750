import '~/setup'

import '~/stylesheets/application.scss'

import Cookies from 'js-cookie'
import imagesLoaded from 'imagesloaded'
import Masonry from 'masonry-layout'
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css'


const routine = () => {
    const onReadies = window.onReadies || [];
    const onResizes = window.onResizes || [];
    const onOrientationChanges = window.onOrientationChanges || [];

    // cookies bar
    let cookiesAllowed = Cookies.get('allow_cookies');
    const cookiesBar = document.getElementById('cookies-bar');

    const allowCookies = () => {
        Cookies.set('allow_cookies', 'yes', {
            expires: 365
        });
        cookiesAllowed = 'yes';
        cookiesBar.style.display = 'none';
    };

    // allow cookies by clicking on any link (including the cookies bar button)
    const closeBarLinks = cookiesBar.getElementsByClassName('close-bar');
    closeBarLinks[0].addEventListener('click', () => {
        if (cookiesAllowed !== 'yes') {
            return allowCookies();
        }
    });

    const resizeCookiesBar = () => {
        if (cookiesAllowed === 'yes') {
            allowCookies();
            return;
        }
        // const pHeight = cookiesBar.querySelector('.text-center').offsetHeight + 10;
        // cookiesBar.style.height = pHeight + 'px';
    };

    onReadies.push(resizeCookiesBar);
    onResizes.push(resizeCookiesBar);
    onOrientationChanges.push(resizeCookiesBar);

    //--------------------------------------------------

    // Masonry + imagesLoaded plugin
    const masonryContainer = document.querySelector('.masonry-container');
    if(masonryContainer) {
        imagesLoaded(masonryContainer, () => {
            new Masonry(masonryContainer, {
                columnWidth: '.masonry-card',
                itemSelector: '.masonry-card',
                gutter: 20,
            });
        });
    }

    //--------------------------------------------------

    // Glightbox configuration
    const galleryElements = document.getElementsByClassName('glightbox')
    if (galleryElements.length > 0) {
        const  lightbox = GLightbox();
    }

    //--------------------------------------------------

    const mobileMenuIcon = document.getElementById('open-menu-icon');
    const mobileOverlay = document.getElementById('mobile-menu-overlay');
    const mobileOverlayMenuIcon = mobileOverlay.querySelector('.close-mobile-menu');

    mobileOverlayMenuIcon.addEventListener('click', () => {
        mobileOverlay.classList.add('hidden');
    });

    mobileMenuIcon.addEventListener('click', () => {
        mobileOverlay.classList.remove('hidden');
    });

    //--------------------------------------------------

    window.addEventListener('scroll', () => {
        const header = document.querySelector('header.main-header');

        if (!(header instanceof HTMLElement)) { return; }

        if (header.offsetTop >= 80) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    });

    //--------------------------------------------------

    onReadies.forEach((callback) => callback());

    window.addEventListener('resize', () => {
        onResizes.forEach((callback) => callback());
    });

    window.addEventListener('orientationchange', () => {
        onOrientationChanges.forEach((callback) => callback());
    });
};

document.addEventListener('DOMContentLoaded', routine);
window.addEventListener('load', routine);
